import AnimationRevealPage from "helpers/AnimationRevealPage";
import { Helmet } from "react-helmet-async";
// eslint-disable-next-line
import { studentTestimonials} from "data/dummyData";
//import ClassSchedule from "./ClassSchedule";
import CourseFee from "./CourseFee";
import CourseObjective from "./CourseObjective";
import FaqPage from "components/FAQ";
import FooterPage from "components/Footer";
import Overview from "./Overview";
//import RegistrationForm from "./RegistrationForm";
import SEO from "components/seo/SEO";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { courseFaqs } from "data/dummyData";
import { courseFeeData } from "data/dummyData";
import CourseTestimonialPage from "./CourseTestimonial";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";
import RegistrationForm from "./RegistrationForm";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function OnlineGroupCourse() {
  return (
    <AnimationRevealPage>
       <SEO
        title="German Online Group Course - Learn German Online at Weg2De Akademie"
        description="German Online Group Course - Learn German Online at Weg2De Akademie | Learn German Online in Germany - Weg2De Akademie | Learn German from home - Weg2De Akademie, Affordable Online German Schools in Germany - Weg2De Akademie, Best Online German School - Weg2De Akademie, German Individual Courses | German for Professionals "
        name="Weg2De - Akademie"
        type=""
      />

      <Helmet>
        <title>German Online Group Course - Learn German Online at Weg2De Akademie"</title>
        <meta property="og:title" content="German Online Group Course - Learn German Online at Weg2De Akademie" />
        <meta property="og:description" content="German Online Group Course - Learn German Online at Weg2De Akademie | Learn German Online in Germany - Weg2De Akademie | Learn German from home - Weg2De Akademie, Affordable Online German Schools in Germany - Weg2De Akademie, Best Online German School - Weg2De Akademie, German Individual Courses | German for Professionals " />
        <meta property="og:url" content="https://weg2de.com/german-online-group-course" />
      </Helmet>
      <Container>
        <Overview />

        <CourseObjective />
        <CourseTestimonialPage 
        testimonials={studentTestimonials}
        />
        {/*<ClassSchedule />*/}
        <CourseFee data={courseFeeData} />
        <RegistrationForm/>
       
        <FaqPage
          subheading="FAQS"
          heading="Do you have Questions?"
          faqs={courseFaqs}
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default OnlineGroupCourse;
